import AvaxIcon from "../assets/networks/avalanche-icon.png";
import FtmIcon from "../assets/networks/fantom-icon.svg";
import EthIcon from "../assets/networks/ethereum-icon.svg";
import AethIcon from "../assets/networks/ethereum-icon.svg";
import BscIcon from "../assets/networks/token-logo.png";
import BaseIcon from '../assets/networks/base-icon.svg'

export const TOKEN_DECIMALS = 18;

export enum Networks {
    AVAX = 43114,
    FANTOM = 250,
    ETH = 1,
    AETH = 42161,
    BSC = 97,
    BASE = 8453,
    SEPOLIA = 11155111
}

export const DEFAULD_NETWORK = Networks.BASE;

export const AVAILABLE_CHAINS = [Networks.BSC, Networks.BASE];
export const WMEMO_BRIDG_CHAINS = [Networks.AVAX, Networks.FANTOM, Networks.ETH, Networks.AETH, Networks.BASE, Networks.BSC];
export const BALANCE_CHAINS = [Networks.BSC, Networks.BASE];

export const NetworksInfo = {
    [Networks.AVAX]: {
        chainId: "0xa86a",
        chainName: "Avalanche",
        shortName: "AVAX",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18,
        },
        img: AvaxIcon,
    },
    [Networks.FANTOM]: {
        chainId: "0xfa",
        chainName: "Fantom",
        shortName: "FTM",
        rpcUrls: ["https://rpc.ftm.tools/", "https://rpcapi.fantom.network/", "https://rpc.fantom.network/"],
        blockExplorerUrls: ["https://ftmscan.com/"],
        nativeCurrency: {
            name: "Fantom",
            symbol: "FTM",
            decimals: 18,
        },
        img: FtmIcon,
    },
    [Networks.ETH]: {
        chainId: "0x1",
        chainName: "Ethereum",
        shortName: "ETH",
        rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
        blockExplorerUrls: ["https://etherscan.io"],
        nativeCurrency: {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
        },
        img: EthIcon,
    },
    [Networks.AETH]: {
        chainId: "0xa4b1",
        chainName: "Arbitrum",
        shortName: "AETH",
        rpcUrls: ["https://eth1.ethitrum.io/rpc"],
        blockExplorerUrls: ["https://arbiscan.io"],
        nativeCurrency: {
            name: "Ether",
            symbol: "AETH",
            decimals: 18,
        },
        img: AethIcon,
    },
    [Networks.BSC]: {
        chainId: "0x61",
        chainName: "BSC",
        shortName: "BNB",
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
        },
        img: BscIcon,
    },
    [Networks.BASE]: {
        chainId: "0x21d5",
        chainName: "Ethereum",
        shortName: "ETH",
        rpcUrls: ["https://mainnet.base.org"],
        blockExplorerUrls: ["https://basescan.org/"],
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        img: BaseIcon,
    },
    [Networks.SEPOLIA]: {
        chainId: "0xaa36A7",
        chainName: "Ethereum",
        shortName: "ETH",
        rpcUrls: ["https://ethereum-sepolia.rpc.subquery.network/public"],
        blockExplorerUrls: ["https://sepolia.etherscan.org/"],
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        img: BaseIcon,
    },    
};
