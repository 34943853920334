import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./social";
import StakeIcon from "../../../assets/icons/stake.svg";
import BondIcon from "../../../assets/icons/bond.svg";
import WonderlandIcon from "../../../assets/images/logo-admin.png";
import DashboardIcon from "../../../assets/icons/dashboard.svg";
import { trim, shorten } from "../../../helpers";
import { useAddress, useWeb3Context } from "../../../hooks";
import useBonds from "../../../hooks/bonds";
import { Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./drawer-content.scss";
import DocsIcon from "../../../assets/icons/stake.svg";
import GlobeIcon from "../../../assets/icons/wonderglobe.svg";
import classnames from "classnames";
import BridgeIcon from "../../../assets/icons/bridge-alt.svg";
import { Networks, VIEWS_FOR_NETWORK } from "../../../constants";
import BlogIcon from "../../../assets/icons/medium.svg";
import FundIcon from "../../../assets/icons/fund.png";
import RedemptionIcon from "../../../assets/icons/redemption.svg";
import FarmIcon from "../../../assets/icons/farm.svg";
import leftuser from "../../../assets/images/left-user.png";
import ConnectButton from "../../Header/connect-button";
import { IReduxState } from "src/store/slices/state.interface";
import { useSelector } from "react-redux";
function NavContent() {
    const [isActive] = useState();
    const address = useAddress();
    const { bonds } = useBonds();
    const { chainID, disconnect, connected, web3, providerChainID, checkWrongNetwork } = useWeb3Context();
    const stakingTVL = useSelector<IReduxState, number>(state => {
        return state.app.stakingTVL;
    });
    const ethBalance = useSelector<IReduxState, string>(state => {
        console.log("debug->state", state.account.balances, state.account.balances.eth);
        return state.account.balances && state.account.balances.eth;
    });
    console.log("debug->ethBalance", ethBalance);
    const totalparticipants = useSelector<IReduxState, number>(state => {
        return state.app.totalParticipants;
    });
    const totalRefBonus = useSelector<IReduxState, number>(state => {
        return state.app.totalRefBonus;
    });
    const ethPrice = useSelector<IReduxState, number>(state => {
        return state.app.ethPrice;
    });
    const fundsLocked = (Number(ethPrice) + 0.214) * stakingTVL;

    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const checkPage = useCallback((location: any, page: string): boolean => {
        const currentPath = location.pathname.replace("/", "");
        if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
            return true;
        }
        if (currentPath.indexOf("stake") >= 0 && page === "stake") {
            return true;
        }
        if (currentPath.indexOf("referal") >= 0 && page === "referal") {
            return true;
        }
        if (currentPath.indexOf("transaction") >= 0 && page === "transaction") {
            return true;
        }
        if (currentPath.indexOf("mints") >= 0 && page === "mints") {
            return true;
        }
        if (currentPath.indexOf("farm") >= 0 && page === "farm") {
            return true;
        }
        if (currentPath.indexOf("calculator") >= 0 && page === "calculator") {
            return true;
        }
        if (currentPath.indexOf("bridge") >= 0 && page === "bridge") {
            return true;
        }
        if (currentPath.indexOf("fund") >= 0 && page === "fund") {
            return true;
        }
        if (currentPath.indexOf("blog") >= 0 && page === "blog") {
            return true;
        }
        if (currentPath.indexOf("redemption") >= 0 && page === "redemption") {
            return true;
        }
        return false;
    }, []);

    return (
        <div className="dapp-sidebar">
            <div className="branding-header">
                <div className=" this logo-admin this">
                    {/* <a href="#"> <img alt="" src={WonderlandIcon} /> */}
                    <div className="staking-title staking-head">
                        <h2>
                            <span className="text-secondary">Fortuna Staking</span> 
                        </h2>
                    </div>
                    {/* </a> */}
                </div>

                <div className="left-user text-center">
                    {/* <img src={leftuser} /> */}
                    <div className="left-address">
                        {/* <button className="btn btn-primary">
                            <i className="fa-solid fa-wallet"></i> Connect Wallet
                        </button> */}
                    </div>
                </div>
                <ConnectButton />
                {address && (
                    <>
                        <div className="disconnect" onClick={disconnect}>
                            Disconnect Wallet
                        </div>
                        <h4>
                            <b>Wallet Balance:</b> {formatter.format(parseInt(ethBalance))} FTN
                        </h4>
                    </>
                )}
            </div>

            <div className="dapp-menu-links">
                <div className="dapp-nav">
                    {/* {VIEWS_FOR_NETWORK[chainID].dashboard && (
                        <Link
                            component={NavLink}
                            to="/dashboard"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "dashboard");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={DashboardIcon} />
                                <p>Dashboard</p>
                            </div>
                        </Link>
                    )} */}

                    {VIEWS_FOR_NETWORK[chainID].stake && (
                        <Link
                            component={NavLink}
                            to="/stake"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "stake");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <i className="fa-solid fa-coins" style={{ marginRight: "10px" }}></i>
                                <p>Staking</p>
                            </div>
                        </Link>
                    )}
                    {/* {VIEWS_FOR_NETWORK[chainID].transaction && (
                        <Link
                            component={NavLink}
                            to="/transaction"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "transaction");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <i className="fa-solid fa-list-ul" style={{ marginRight: "10px" }}></i>
                                <p>Transaction</p>
                            </div>
                        </Link>
                    )} */}
                    {VIEWS_FOR_NETWORK[chainID].referal && (
                        <Link
                            component={NavLink}
                            to="/graph"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "graph");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <i className="fa-solid fa-users-gear" style={{ marginRight: "10px" }}></i>
                                <p>APY Graph</p>
                            </div>
                        </Link>
                    )}
                    {/* {VIEWS_FOR_NETWORK[chainID].referal && (
                        <Link
                            component={NavLink}
                            to="/promotion"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "promotion");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <i className="fa-solid fa-image" style={{ marginRight: "10px" }}></i>

                                <p>Promotional Materials</p>
                            </div>
                        </Link>
                    )} */}

                    {/* {VIEWS_FOR_NETWORK[chainID].mints && (
                        <Link
                            component={NavLink}
                            id="bond-nav"
                            to="/mints"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "mints");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={BondIcon} />
                                <p>{chainID === Networks.AVAX ? "Mint" : "Treasury sales"}</p>
                            </div>
                        </Link>
                    )}

                    {VIEWS_FOR_NETWORK[chainID].mints && (
                        <div className="bond-discounts">
                            <p>{chainID === Networks.AVAX ? "Mint" : "Treasury sale"} discounts</p>
                            {bonds.map((bond, i) => {
                                if (bond.getAvailability(chainID)) {
                                    return (
                                        <Link component={NavLink} to={`/mints/${bond.name}`} key={i} className={"bond"}>
                                            {!bond.bondDiscount ? (
                                                <Skeleton variant="text" width={"150px"} />
                                            ) : (
                                                <p className={classnames({ deprecated: bond.deprecated })}>
                                                    {bond.displayName}
                                                    {bond.deprecated ? (
                                                        <p className="bond-pair-roi">0%</p>
                                                    ) : (
                                                        <p className="bond-pair-roi">{bond.soldOut ? "Sold out" : bond.bondDiscount && `${trim(bond.bondDiscount * 100, 2)}%`}</p>
                                                    )}
                                                </p>
                                            )}
                                        </Link>
                                    );
                                }
                            })}
                        </div>
                    )}

                    {VIEWS_FOR_NETWORK[chainID].redemption && (
                        <Link
                            component={NavLink}
                            to="/redemption"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "redemption");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={RedemptionIcon} />
                                <p>Redemption</p>
                            </div>
                        </Link>
                    )}

                    {VIEWS_FOR_NETWORK[chainID].farm && (
                        <Link
                            component={NavLink}
                            id="farm-nav"
                            to="/farm"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "farm");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={FarmIcon} />
                                <p>Farm</p>
                            </div>
                        </Link>
                    )}

                    <Link
                        component={NavLink}
                        to="/bridge"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "bridge");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={BridgeIcon} />
                            <p>Bridge</p>
                        </div>
                    </Link>

                    {VIEWS_FOR_NETWORK[chainID].fund && (
                        <Link
                            component={NavLink}
                            to="/fund"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "fund");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={FundIcon} />
                                <p>Fund</p>
                            </div>
                        </Link>
                    )}

                    <Link
                        component={NavLink}
                        to="/blog"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "blog");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={BlogIcon} />
                            <p>Blog</p>
                        </div>
                    </Link>

                    {VIEWS_FOR_NETWORK[chainID].calculator && (
                        <Link
                            component={NavLink}
                            to="/calculator"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "calculator");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <img alt="" src={GlobeIcon} />
                                <p>Calculator</p>
                            </div>
                        </Link>
                    )} */}
                </div>
            </div>
            <div className="project-stats">
                <div className="left-box">
                    <h3>Statistics</h3>
                    <table>
                        <tbody>
                            {/* <tr className="st">
                                <th>Funds Locked</th>
                                <td>$ {Number(fundsLocked).toFixed(2)}</td>
                            </tr> */}
                            <tr className="st">
                                <th>Total $FTN Locked</th>
                                <td>{Number(stakingTVL).toFixed(2)} FTN</td>
                            </tr>
                            <tr className="st">
                                <th>Participants</th>
                                <td>{Number(totalparticipants).toString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Social />
        </div>
    );
}

export default NavContent;
