import React, { useEffect, useState } from "react";
import "./transaction.css";
import Footer from "../Stake/component/Footer";
import Moralis from "moralis";
import { ethers } from "ethers";
import { getAddresses, WONDERLAND_API } from "../../constants";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useWeb3Context } from "src/hooks";
import { StakingContract } from "../../abi";
import { getChainInfo } from "src/helpers/get-chains";
import { Networks } from "../../constants/blockchain";
import { decodeDate } from "use-query-params";
import { trim } from "src/helpers";
import { filter } from "lodash";

const Transaction = () => {
    const [activeFilter, setActiveFilter] = useState("All Transaction");
    const { address: account, connect } = useWeb3Context();
    const addresses = getAddresses(Networks.BASE);
    const stakingAddress = addresses.STAKING_ADDRESS;
    const provider = new ethers.providers.JsonRpcProvider("https://mainnet.base.org");
    const staingContract = new ethers.Contract(stakingAddress, StakingContract, provider);
    const contractInterface = new ethers.utils.Interface(StakingContract);

    const [results, setResults] = useState([]);
    const { chainId, chainName, rpcUrls, blockExplorerUrls, nativeCurrency } = getChainInfo(Networks.BASE);

    const filters = ["All Transaction", "Stake", "Earning"];

    const functionSignature = ["stake(address, uint8, uint256)", "compound(uint8)", "withdraw()", ];
    const handleFilterClick = (filter: any) => {
        setActiveFilter(filter);
    };

    useEffect(() => {
        let response: any;
        const runApp = async () => {
            if (!Moralis.Core.isStarted) {
                await Moralis.start({
                    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjAyYzVmMzNlLWVhNGYtNGI3MS04NGJmLWI3NDA3NmZmNGQwMCIsIm9yZ0lkIjoiODQ0OTIiLCJ1c2VySWQiOiI4NDEzNiIsInR5cGVJZCI6IjFmODc0MGZjLWY4NTUtNDllNy05MzRmLTI5NGVkMTdkNDczOSIsInR5cGUiOiJQUk9KRUNUIiwiaWF0IjoxNjg1NjExOTc3LCJleHAiOjQ4NDEzNzE5Nzd9.6OCU80IyNiCz1MqXoDDeyLTvCkQ0lFP6wFDkpsqX8zg",
                });
            }

            const address = "0x112bcaad766e02a514491bfcf5c22fbf81a1da58";
            const chain = EvmChain.BASE_TESTNET;

            response = await Moralis.EvmApi.transaction.getWalletTransactionsVerbose({
                address,
                chain,
            });

            response = response.toJSON().result.filter(res => res.from_address.toLocaleLowerCase() === account.toLocaleLowerCase() && res.decoded_call.label !== "approve");
            setResults(response);
        };

        runApp();
    }, [account]);

    interface Transaction {
        id: string;
        type: string;
        amount: string;
        time: string;
        description: string;
    }

    const transactions: Transaction[] = [];
    for (let i = 0; i < results.length; i++) {
        let description = "";
        let type = "";
        let decodedData = "";
        let stakeAmount = "";
        const input = results[i]?.input;

        // Parse the original date and time string into a Date object
        const dateTime = new Date(results[i]?.block_timestamp);

        const formattedDateTime = `${new Date(dateTime).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
          }).replace(/, /, '-')} +UTC`;
        const transaction: Transaction = {
            id: results[i]?.hash,
            type: type,
            amount: stakeAmount,
            time: formattedDateTime,
            description: description,
        };
        switch (results[i]?.decoded_call.label) {
            case "stake":
                transaction.type = "Stake";
                decodedData = contractInterface.decodeFunctionData(functionSignature[0], input)[2];
                const planId = contractInterface.decodeFunctionData(functionSignature[0], input)[1];
                if (planId == 0) {
                    transaction.description = "Staked in ETH STAKE Pool";
                } else if (planId == 1) {
                    transaction.description = "Staked in HORSE UP Pool";
                } else if (planId == 2) {
                    transaction.description = "Staked in ETH CROWN Pool";
                } else if (planId == 3) {
                    transaction.description = "Staked in GROK ME Pool";
                }
                transaction.amount = decodedData ? ethers.utils.formatUnits(decodedData).toString() : "0";
                break;
            case "withdraw":
                const input1 = results[i]?.logs[0].decoded_event.params[2].value;
                const displayAmount1 = trim(Number(input1) / 10 ** 18, 2);
                transaction.amount = displayAmount1.toString();
                transaction.type = "Earning";
                transaction.description = "Claim Earning";
                break;
            case "compound":
                let input2;
                let decimalInput;
                let filterIndex;
                if (results[i].logs.length > 0) {
                    let maxLogIndex = 0;
                    for (let index = 0; index < results[i].logs.length; index++) {
                        let logIndex = results[i].logs[index].log_index;
                        if (logIndex > maxLogIndex) {
                            maxLogIndex = logIndex;
                            filterIndex = index;
                        }
                    }

                    input2 = results[i].logs[filterIndex].data;
                    decimalInput = parseInt(input2, 16);
                }
                transaction.amount = decimalInput ? trim(Number(ethers.utils.formatEther(decimalInput.toString())), 2) : "failed";
                transaction.type = "Compound";
                transaction.description = "Compound";
                break;
            default:
                description = "Referral Commission from level-1";
                break;
        }

        transactions.push(transaction);
    }

    const filteredTransactions = activeFilter === "All Transaction" ? transactions : transactions.filter(transaction => transaction.type === activeFilter);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <div className="filter-wrapper">
                        <ul className="filter-list clearfix">
                            {filters.map((filter, index) => (
                                <li key={index}>
                                    <div className="filter-box">
                                        <input type="radio" name="type" id={`trans-${index + 1}`} checked={filter === activeFilter} onChange={() => handleFilterClick(filter)} />
                                        <label htmlFor={`trans-${index + 1}`}>
                                            <span></span>
                                            {filter}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="trans-table-div">
                        <h3 className="dahsboard-sub-title">Your Transactions</h3>
                        <table className="trans-table">
                            <thead>
                                <tr>
                                    <th>Tx.ID</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th className="text-right">Time</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>
                                            <a target="_blank" href={`${blockExplorerUrls}` + "/tx/" + `${transaction.id}`}>{`${transaction.id.substring(
                                                0,
                                                10,
                                            )}...${transaction.id.substring(transaction.id.length - 4)}`}</a>
                                        </td>
                                        <td>{transaction.type}</td>
                                        <td className={transaction.type === "Earning" ? "red" : "green"}>{transaction.amount}</td>
                                        <td className="text-right">{transaction.time}</td>
                                        <td>{transaction.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Transaction;
