import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GitHub } from "../../../assets/icons/github.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../../assets/icons/discord.svg";

export default function Social() {
    return (
        // <div className="social-row">
        //     <Link href="https://github.com/EthMining-DAO" target="_blank">
        //         <SvgIcon color="primary" component={GitHub} />
        //     </Link>

        //     <Link href="https://twitter.com/EthMining_fi" target="_blank">
        //         <SvgIcon color="primary" component={Twitter} />
        //     </Link>

        //     <Link href="https://discord.gg/thDHseaHUt" target="_blank">
        //         <SvgIcon color="primary" component={Discord} />
        //     </Link>
        // </div>
        <ul className="admin-social">
            <li>
                <a target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="" href="https://x.com/fortunaftncoin" data-bs-original-title="Follow us" aria-label="Follow us">
                    <i className="fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a target="_blank" href="https://t.me/fortunasociety" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="News Channel" aria-label="News Channel">
                    <i className="fas fa-paper-plane"></i>
                </a>
            </li>
        </ul>
    );
}
